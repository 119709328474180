import { Asset } from './Asset';
import { Seo } from './Seo';
import { CityContent } from './CityContent';
import { EntityTranslation } from './EntityTranslation';
import { CityService } from './CityService';

export interface City {
  id: number;
  name: string;
  description: string;
  image: Asset | null;
  images: Asset[];
  seo: Seo | null;
  contents: CityContent[];
  translations: EntityTranslation[];
  locations: Array<string>;
  services: CityService[];
  isForeign: boolean;
}

export interface CityOption {
  value: number;
  label: string;
}

export enum CityOptions {
  // eslint-disable-next-line no-unused-vars
  FOREIGN = 'FOREIGN',
}
