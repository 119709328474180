import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../../common/Loader/Loader';
import { StoreState } from '../StoreProvider/StoreProvider';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as authService from '../../store/auth/service';
import * as userService from '../../store/user/service';
import { routes } from './routes';
import Layout from '../../common/Layout/Layout';
import jwtDecode from 'jwt-decode';
import { JwtToken } from '../Axios/axios-instance';
import { logout, selectLocale } from '../../store/auth/actions';
import * as languageService from '../../store/language/service';
import { Language } from '../../domain/Language';
import { IntlProvider } from 'react-intl';
import { DEFAULT_LOCALE } from '../constants';
import { Locale } from '../../domain/Translation';
import { getLocaleUrl } from '../../utility/url/urlHelper';
import 'moment/locale/lt';
import 'moment/locale/ru';
import 'moment/locale/en-gb';
import moment from 'moment';
import ErrorFallback from '../../common/ErrorFallback/ErrorFallback';
import { ErrorBoundary } from 'react-error-boundary';

const Login = React.lazy(() => import('../../pages/Admin/Login/Login'));

const UsersListPage = React.lazy(
  () => import('../../pages/Admin/User/UsersListPage/UsersListPage'),
);
const UserCreatePage = React.lazy(
  () => import('../../pages/Admin/User/UserCreatePage/UserCreatePage'),
);
const UserEditPage = React.lazy(
  () => import('../../pages/Admin/User/UserEditPage/UserEditPage'),
);

const BlogsListPage = React.lazy(
  () => import('../../pages/Admin/Blog/BlogsListPage/BlogsListPage'),
);
const BlogCreatePage = React.lazy(
  () => import('../../pages/Admin/Blog/BlogCreatePage/BlogCreatePage'),
);
const BlogEditPage = React.lazy(
  () => import('../../pages/Admin/Blog/BlogEditPage/BlogEditPage'),
);

const CitiesListPage = React.lazy(
  () => import('../../pages/Admin/City/CitiesListPage/CitiesListPage'),
);
const CityCreatePage = React.lazy(
  () => import('../../pages/Admin/City/CityCreatePage/CityCreatePage'),
);
const CityEditPage = React.lazy(
  () => import('../../pages/Admin/City/CityEditPage/CityEditPage'),
);

const LocationsListPage = React.lazy(
  () =>
    import('../../pages/Admin/Location/LocationsListPage/LocationsListPage'),
);
const LocationCreatePage = React.lazy(
  () =>
    import('../../pages/Admin/Location/LocationCreatePage/LocationCreatePage'),
);
const LocationEditPage = React.lazy(
  () => import('../../pages/Admin/Location/LocationEditPage/LocationEditPage'),
);

const RoomsListPage = React.lazy(
  () => import('../../pages/Admin/Room/RoomsListPage/RoomsListPage'),
);
const RoomCreatePage = React.lazy(
  () => import('../../pages/Admin/Room/RoomCreatePage/RoomCreatePage'),
);
const RoomEditPage = React.lazy(
  () => import('../../pages/Admin/Room/RoomEditPage/RoomEditPage'),
);

const AgesListPage = React.lazy(
  () => import('../../pages/Admin/Age/AgesListPage/AgesListPage'),
);
const AgeCreatePage = React.lazy(
  () => import('../../pages/Admin/Age/AgeCreatePage/AgeCreatePage'),
);
const AgeEditPage = React.lazy(
  () => import('../../pages/Admin/Age/AgeEditPage/AgeEditPage'),
);

const ProgramsListPage = React.lazy(
  () => import('../../pages/Admin/Program/ProgramsListPage/ProgramsListPage'),
);
const ProgramEditPage = React.lazy(
  () => import('../../pages/Admin/Program/ProgramEditPage/ProgramEditPage'),
);

const HomePage = React.lazy(
  () => import('../../pages/Public/HomePage/HomePage'),
);

const DynamicPage = React.lazy(
  () => import('../../pages/Public/DynamicPage/DynamicPage'),
);

const LocationPage = React.lazy(
  () => import('../../pages/Public/LocationPage/LocationPage'),
);

const SearchPage = React.lazy(
  () => import('../../pages/Public/SearchPage/SearchPage'),
);

const CheckoutPage = React.lazy(
  () => import('../../pages/Public/CheckoutPage/CheckoutPage'),
);

const AmenitiesListPage = React.lazy(
  () => import('../../pages/Admin/Amenity/AmenitiesListPage/AmenitiesListPage'),
);

const EnquiriesListPage = React.lazy(
  () => import('../../pages/Admin/Enquiry/EnquiriesListPage/EnquiriesListPage'),
);

const BasketsListPage = React.lazy(
  () => import('../../pages/Admin/Basket/BasketsListPage/BasketsListPage'),
);

const TranslationsListPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/Translation/TranslationListPage/TranslationListPage'
    ),
);

const LocationGroupsListPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/LocationGroup/LocationGroupsListPage/LocationGroupsListPage'
    ),
);
const LocationGroupCreatePage = React.lazy(
  () =>
    import(
      '../../pages/Admin/LocationGroup/LocationGroupCreatePage/LocationGroupCreatePage'
    ),
);
const LocationGroupEditPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/LocationGroup/LocationGroupEditPage/LocationGroupEditPage'
    ),
);

const ReviewListPage = React.lazy(
  () => import('../../pages/Admin/Review/ReviewListPage/ReviewListPage'),
);

const FaqListPage = React.lazy(
  () => import('../../pages/Admin/Faq/FaqListPage/FaqListPage'),
);
const FaqCreatePage = React.lazy(
  () => import('../../pages/Admin/Faq/FaqCreatePage/FaqCreatePage'),
);
const FaqEditPage = React.lazy(
  () => import('../../pages/Admin/Faq/FaqEditPage/FaqEditPage'),
);

const BlogPage = React.lazy(
  () => import('../../pages/Public/BlogPage/BlogPage'),
);

export type Props = {
  isInitCompleted: boolean;
  isAuthenticated: boolean;
  onTryAutoSignup: () => void;
  isCurrentUserLoading: boolean;
  refreshTokenLoading: boolean;
  onFetchCurrentUser: () => void;
  onRefreshToken: () => void;
  jwtToken: string | null;
  lastActionAt: moment.Moment | null;
  onLogout: () => void;
  selectedLocation: number | null;
  onLanguageFetch: (locale: string) => void;
  language: Language | null;
  onLanguagesInit: () => void;
  onSelectLocale: (locale: Locale) => void;
  selectedLocale: Locale;
  languages: Language[] | null;
  currentLanguage: string | null;
  onFetchCurrentLanguage: () => void;
  path: string;
};

const ALLOWED_LOCALES = ['en', 'lt', 'ru'];

export const Router = ({
  isInitCompleted,
  isAuthenticated,
  onTryAutoSignup,
  isCurrentUserLoading,
  onFetchCurrentUser,
  refreshTokenLoading,
  onRefreshToken,
  jwtToken,
  lastActionAt,
  onLogout,
  selectedLocation,
  onLanguageFetch,
  language,
  onLanguagesInit,
  selectedLocale,
  onSelectLocale,
  languages,
  currentLanguage,
  onFetchCurrentLanguage,
  path,
}: Props) => {
  const locale = window.location?.pathname
    ?.substring(1)
    ?.split('/')?.[0]
    .toLowerCase();

  const localeFromPath = path?.substring(1)?.split('/')?.[0].toLowerCase();

  const parsedLocale = (
    locale && ALLOWED_LOCALES.includes(locale) ? locale : DEFAULT_LOCALE
  ) as Locale;

  useEffect(() => {
    if (!jwtToken) {
      return;
    }

    const decodedJson: JwtToken = jwtDecode(jwtToken);

    if (!decodedJson) {
      return;
    }

    const difference = moment.duration(
      moment(decodedJson.exp * 1000).diff(moment()),
    );
    const differenceLastAction = moment.duration(moment().diff(lastActionAt));

    if (
      difference.asMinutes() < 5 &&
      differenceLastAction.asMinutes() < 5 &&
      !refreshTokenLoading
    ) {
      onRefreshToken();
    }

    const timeout = setTimeout(() => {
      onLogout();
    }, difference.asMilliseconds());

    return () => clearTimeout(timeout);
  }, [jwtToken, lastActionAt]);

  useEffect(() => {
    onTryAutoSignup();
  }, []);

  useEffect(() => {
    onFetchCurrentUser();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      onFetchCurrentUser();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!parsedLocale) {
      onLanguageFetch(parsedLocale ?? DEFAULT_LOCALE);
      return;
    }

    moment.locale(parsedLocale === 'en' ? 'en-gb' : parsedLocale);
    onLanguageFetch(parsedLocale);
    onLanguagesInit();
    onSelectLocale(parsedLocale);
  }, [parsedLocale]);

  useEffect(() => {
    if (!currentLanguage) {
      onFetchCurrentLanguage();
      return;
    }

    if (currentLanguage !== selectedLocale && !localeFromPath) {
      onSelectLocale(currentLanguage as Locale);
    }
  }, [currentLanguage]);

  const getCurrentLanguage = () => {
    const foundLanguage = languages?.find(
      (singleLanguage) => singleLanguage.locale === selectedLocale,
    );

    return foundLanguage ?? language;
  };

  const mappedTranslations = getCurrentLanguage()?.translations.reduce(
    (obj, item) =>
      Object.assign(obj, {
        [item.alias]: item.value ? item.value : item.defaultValue,
      }),
    {},
  );

  return (
    <BrowserRouter basename="/">
      {isInitCompleted && !isCurrentUserLoading && language ? (
        <IntlProvider
          messages={mappedTranslations}
          locale={language?.locale ?? DEFAULT_LOCALE}
          defaultLocale="en"
        >
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              window.location.reload();
            }}
          >
            <Suspense fallback={<Loader isLoading isFullScreen />}>
              <Layout isAuthenticated={isAuthenticated}>
                <Routes>
                  {!isAuthenticated && (
                    <>
                      <Route
                        path={routes.admin}
                        element={<Navigate replace to={routes.login} />}
                      />
                    </>
                  )}
                  {isAuthenticated && (
                    <>
                      <Route
                        path={routes.admin}
                        element={<Navigate replace to={routes.users.list} />}
                      />
                      <Route
                        path={routes.users.list}
                        element={<UsersListPage />}
                      />
                      <Route
                        path={routes.users.create}
                        element={<UserCreatePage />}
                      />
                      <Route
                        path={routes.users.edit}
                        element={<UserEditPage />}
                      />
                      <Route
                        path={routes.blogs.list}
                        element={<BlogsListPage />}
                      />
                      <Route
                        path={routes.blogs.create}
                        element={<BlogCreatePage />}
                      />
                      <Route
                        path={routes.blogs.edit}
                        element={<BlogEditPage />}
                      />
                      <Route
                        path={routes.cities.create}
                        element={<CityCreatePage />}
                      />
                      <Route
                        path={routes.cities.edit}
                        element={<CityEditPage />}
                      />
                      <Route
                        path={routes.cities.list}
                        element={<CitiesListPage />}
                      />
                      <Route
                        path={routes.locationGroups.create}
                        element={<LocationGroupCreatePage />}
                      />
                      <Route
                        path={routes.locationGroups.edit}
                        element={<LocationGroupEditPage />}
                      />
                      <Route
                        path={routes.locationGroups.list}
                        element={<LocationGroupsListPage />}
                      />
                      <Route
                        path={routes.reviews}
                        element={<ReviewListPage />}
                      />
                      <Route path={routes.faq.list} element={<FaqListPage />} />
                      <Route
                        path={routes.faq.create}
                        element={<FaqCreatePage />}
                      />
                      <Route path={routes.faq.edit} element={<FaqEditPage />} />
                      {selectedLocation && (
                        <>
                          <Route
                            path={routes.rooms.create}
                            element={<RoomCreatePage />}
                          />
                          <Route
                            path={routes.rooms.edit}
                            element={<RoomEditPage />}
                          />
                          <Route
                            path={routes.rooms.list}
                            element={<RoomsListPage />}
                          />
                          <Route
                            path={routes.ages.create}
                            element={<AgeCreatePage />}
                          />
                          <Route
                            path={routes.ages.edit}
                            element={<AgeEditPage />}
                          />
                          <Route
                            path={routes.ages.list}
                            element={<AgesListPage />}
                          />
                          <Route
                            path={routes.programs.edit}
                            element={<ProgramEditPage />}
                          />
                          <Route
                            path={routes.programs.list}
                            element={<ProgramsListPage />}
                          />
                        </>
                      )}
                      <Route
                        path={routes.locations.create}
                        element={<LocationCreatePage />}
                      />
                      <Route
                        path={routes.locations.edit}
                        element={<LocationEditPage />}
                      />
                      <Route
                        path={routes.locations.list}
                        element={<LocationsListPage />}
                      />
                      <Route
                        path={routes.amenities.list}
                        element={<AmenitiesListPage />}
                      />
                      <Route
                        path={routes.enquiries.list}
                        element={<EnquiriesListPage />}
                      />
                      <Route
                        path={routes.baskets.list}
                        element={<BasketsListPage />}
                      />
                      <Route
                        path={routes.translations.list}
                        element={<TranslationsListPage />}
                      />
                    </>
                  )}
                  <Route path={routes.login} element={<Login />} />
                  <Route
                    path={getLocaleUrl(routes.locationPage, selectedLocale)}
                    element={<LocationPage />}
                  />
                  <Route
                    path={getLocaleUrl(routes.checkout, selectedLocale)}
                    element={<CheckoutPage />}
                  />
                  <Route
                    path={getLocaleUrl(routes.search, selectedLocale)}
                    element={<SearchPage />}
                  />
                  <Route
                    path={getLocaleUrl(routes.dynamicPage, selectedLocale)}
                    element={<DynamicPage />}
                  />
                  <Route
                    path={getLocaleUrl(routes.blogPage, selectedLocale)}
                    element={<BlogPage />}
                  />
                  <Route
                    path={getLocaleUrl(routes.homepage, selectedLocale)}
                    element={<HomePage />}
                  />
                  <Route
                    path="*"
                    element={
                      <Navigate
                        to={getLocaleUrl(routes.homepage, selectedLocale)}
                      />
                    }
                  />
                </Routes>
              </Layout>
            </Suspense>
          </ErrorBoundary>
        </IntlProvider>
      ) : (
        <Loader isLoading isFullScreen />
      )}
    </BrowserRouter>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isInitCompleted: state.auth.isInitCompleted,
  isAuthenticated: state.auth.isAuthenticated,
  isCurrentUserLoading: state.user.currentUserLoading,
  refreshTokenLoading: state.auth.refreshTokenLoading,
  jwtToken: state.auth.jwtToken,
  loginLoading: state.auth.loginGoogleLoading,
  lastActionAt: state.auth.lastStoreActionAt,
  selectedLocation: state.location.selectedLocation,
  language: state.language.language,
  selectedLocale: state.auth.selectedLocale,
  languages: state.language.languages,
  currentLanguage: state.language.currentLanguage,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onTryAutoSignup: () => dispatch(authService.authCheckState()),
  onFetchCurrentUser: () => dispatch(userService.fetchCurrentUser()),
  onRefreshToken: () => dispatch(authService.refreshToken()),
  onLanguageFetch: (locale: string) =>
    dispatch(languageService.fetchLanguage(locale)),
  onLanguagesInit: () => dispatch(languageService.fetchLanguages()),
  onLogout: () => dispatch(logout()),
  onSelectLocale: (locale: Locale) => dispatch(selectLocale(locale)),
  onFetchCurrentLanguage: () =>
    dispatch(languageService.fetchCurrentLanguage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Router);
