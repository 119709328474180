import React from 'react';
import Router from './config/Router/Router';
import StoreProvider from './config/StoreProvider/StoreProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ToastContainer } from 'react-toastify';

const App = () => {
  const path = window.location?.pathname;

  return (
    <GoogleOAuthProvider clientId="1032748908415-evbud07sh84u9lilkjggvd6lbvq2pcif.apps.googleusercontent.com">
      <StoreProvider>
        <Router path={path} />
        <ToastContainer />
      </StoreProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
