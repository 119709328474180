export const FETCH_PROGRAMS_START = 'FETCH_PROGRAMS_START';
export const FETCH_PROGRAMS_SUCCESS = 'FETCH_PROGRAMS_SUCCESS';
export const FETCH_PROGRAMS_FAIL = 'FETCH_PROGRAMS_FAIL';

export const FETCH_PROGRAM_START = 'FETCH_PROGRAM_START';
export const FETCH_PROGRAM_SUCCESS = 'FETCH_PROGRAM_SUCCESS';
export const FETCH_PROGRAM_FAIL = 'FETCH_PROGRAM_FAIL';

export const FETCH_PUBLIC_PROGRAM_START = 'FETCH_PUBLIC_PROGRAM_START';
export const FETCH_PUBLIC_PROGRAM_SUCCESS = 'FETCH_PUBLIC_PROGRAM_SUCCESS';
export const FETCH_PUBLIC_PROGRAM_FAIL = 'FETCH_PUBLIC_PROGRAM_FAIL';

export const FETCH_PUBLIC_PROGRAM_DESCRIPTION_START =
  'FETCH_PUBLIC_PROGRAM_DESCRIPTION_START';
export const FETCH_PUBLIC_PROGRAM_DESCRIPTION_SUCCESS =
  'FETCH_PUBLIC_PROGRAM_DESCRIPTION_SUCCESS';
export const FETCH_PUBLIC_PROGRAM_DESCRIPTION_FAIL =
  'FETCH_PUBLIC_PROGRAM_DESCRIPTION_FAIL';

export const CREATE_PROGRAM_START = 'CREATE_PROGRAM_START';
export const CREATE_PROGRAM_SUCCESS = 'CREATE_PROGRAM_SUCCESS';
export const CREATE_PROGRAM_FAIL = 'CREATE_PROGRAM_FAIL';

export const UPDATE_PROGRAM_START = 'UPDATE_PROGRAM_START';
export const UPDATE_PROGRAM_SUCCESS = 'UPDATE_PROGRAM_SUCCESS';
export const UPDATE_PROGRAM_FAIL = 'UPDATE_PROGRAM_FAIL';

export const DELETE_PROGRAM_START = 'DELETE_PROGRAM_START';
export const DELETE_PROGRAM_SUCCESS = 'DELETE_PROGRAM_SUCCESS';
export const DELETE_PROGRAM_FAIL = 'DELETE_PROGRAM_FAIL';

export const APPEND_PROGRAM_PERIODS = 'APPEND_PROGRAM_PERIODS';
export const APPEND_PROGRAM_NIGHTS_PERIODS = 'APPEND_PROGRAM_NIGHTS_PERIODS';

export const SET_PROGRAM_SORT_ORDER_START = 'SET_PROGRAM_SORT_ORDER_START';
export const SET_PROGRAM_SORT_ORDER_SUCCESS = 'SET_PROGRAM_SORT_ORDER_SUCCESS';
export const SET_PROGRAM_SORT_ORDER_FAIL = 'SET_PROGRAM_SORT_ORDER_FAIL';

export const RESET_CREATED_PROGRAM = 'RESET_CREATED_PROGRAM';

export const RESET_PROGRAM_STORE = 'RESET_PROGRAM_STORE';
export const RESET_PUBLIC_PROGRAM_STORE = 'RESET_PUBLIC_PROGRAM_STORE';

export const LOGOUT = 'LOGOUT';

export type ProgramActionTypes =
  | typeof FETCH_PROGRAMS_START
  | typeof FETCH_PROGRAMS_SUCCESS
  | typeof FETCH_PROGRAMS_FAIL
  | typeof FETCH_PROGRAM_START
  | typeof FETCH_PROGRAM_SUCCESS
  | typeof FETCH_PROGRAM_FAIL
  | typeof FETCH_PUBLIC_PROGRAM_START
  | typeof FETCH_PUBLIC_PROGRAM_SUCCESS
  | typeof FETCH_PUBLIC_PROGRAM_FAIL
  | typeof CREATE_PROGRAM_START
  | typeof CREATE_PROGRAM_SUCCESS
  | typeof CREATE_PROGRAM_FAIL
  | typeof UPDATE_PROGRAM_START
  | typeof UPDATE_PROGRAM_SUCCESS
  | typeof UPDATE_PROGRAM_FAIL
  | typeof DELETE_PROGRAM_START
  | typeof DELETE_PROGRAM_SUCCESS
  | typeof DELETE_PROGRAM_FAIL
  | typeof APPEND_PROGRAM_PERIODS
  | typeof APPEND_PROGRAM_NIGHTS_PERIODS
  | typeof SET_PROGRAM_SORT_ORDER_START
  | typeof SET_PROGRAM_SORT_ORDER_SUCCESS
  | typeof SET_PROGRAM_SORT_ORDER_FAIL
  | typeof FETCH_PUBLIC_PROGRAM_DESCRIPTION_START
  | typeof FETCH_PUBLIC_PROGRAM_DESCRIPTION_SUCCESS
  | typeof FETCH_PUBLIC_PROGRAM_DESCRIPTION_FAIL
  | typeof RESET_CREATED_PROGRAM
  | typeof RESET_PROGRAM_STORE
  | typeof RESET_PUBLIC_PROGRAM_STORE
  | typeof LOGOUT;
